<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Blog Categories/Create</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <form action="">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-8">
                <div class="form-group">
                    <div class="">
                      <label>Blog Category Name</label>
                      <input v-model="postBlogCategoryData.name" type="text" class="form-control"
                             placeholder="Enter Blog Category Name" name="blogName">
                      <div class="text-danger" v-if="errors.name">{{ errors.name }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4">
                  <div class="form-group">
                    <div class="controls">
                      <label>Status</label>
                      <div style="margin-top: .5rem">
                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                          <div>
                            <input v-model="selectedStatus" type="checkbox" class="custom-control-input" checked
                                   id="blogCategoryStatus">
                            <label class="custom-control-label mr-1" for="blogCategoryStatus"></label>
                          </div>
                          <span class="font-medium-1">{{ selectedStatus ? "Active" : "Inactive" }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="text-danger" v-if="errors.status">{{ errors.status }}</div>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-12 ">
                  <div class="d-flex justify-content-end">
                    <button @click="createSingleBlogCategory"
                            type="button" class="btn btn-primary update-todo px-4">Add Blog Category
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import {mapActions, mapGetters} from "vuex";

import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  name: "BlogCategoryCreate",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
  },
  data() {
    return {
      getBlogCategoriesParams: {
        status: 1,
        order_by_name: 'ASC',
      },

      selectedStatus: true,

      postBlogCategoryData: {
        name: '',
        status: 1,
      },
      errors: {
        name: '',
        status: '',
      }
    }
  },
  watch: {
    selectedStatus(selectedStatus) {
      this.postBlogCategoryData.status = selectedStatus === true ? 1 : 0;
    }
  },

  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      blogCategories: 'appBlogCategories/blogCategories'
    }),
    redirectRouteName() {
      return this.can('blog-category-view-any') ? 'appBlogCategoryList' : 'appBlogCategoryCreate';
    },
  },
  methods: {
    ...mapActions({
      postBlogCategory: 'appBlogCategories/postBlogCategory',
      getBlogCategories: 'appBlogCategories/getBlogCategories',
    }),

    async getBlogCategoryList() {
      await this.getBlogCategories(this.getBlogCategoriesParams);
    },
    async createSingleBlogCategory() {
      await this.loader(true);
      await this.postBlogCategory(this.postBlogCategoryData).then(async (response) => {
        await this.loader(false);
        if (response.status === 201) {
          const toastObj = {message: 'Blog Category Created Successful.', type: 'success'};
          this.showToastMessage(toastObj);
          await this.$router.replace({name: this.redirectRouteName});
        }

        this.errors.name = response?.errors?.name[0] ?? '';
        this.errors.status = response?.errors?.status[0] ?? '';
        if (response.message) {
          this.showToastMessage(response);
        }
      });
    },

  },
  async mounted() {
    await this.loader(true);
    await this.getBlogCategoryList();
    await this.loader(false);

  },
}
</script>

<style scoped>

</style>